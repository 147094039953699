import {Polygon} from "@react-google-maps/api";

export const polygons: any = (polygonCoords: []) => {
    return <Polygon
        path={polygonCoords}
        options={{
            fillColor: "#FF0000",
            strokeColor: `#FF0000`,
            strokeWeight: 2,
        }}
    />
}
