import {Polyline} from "@react-google-maps/api";
import {Feature, GeoJson} from "../models/geojson";

export const lines = (jsonLines: GeoJson) => {

    if (jsonLines != null) {
        return jsonLines['features'].map((data: Feature) => {
            let coordinates = data["geometry"]["coordinates"];
            let cordArr: any[] = [];
            coordinates.map((coordinate: any[]) => cordArr.push({lat: coordinate[1], lng: coordinate[0]}))

            return (
                <Polyline
                    path={cordArr}
                    options={{
                        strokeColor: `${data["properties"]["ft_LayerHexColor"]}`,
                        strokeWeight: 2,
                    }}
                />
            )
        })
    }
}
