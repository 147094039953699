export const currentLocation = (setCentered: any, setActiveLoc: any) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(pos) {
            setCentered({
                lat: pos.coords.latitude,
                lng: pos.coords.longitude
            })
            setActiveLoc(true);
        });
    } else {
        console.log("Geolocation is not supported by this browser.");
    }
}
