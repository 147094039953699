import { useLoadScript } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import { Map } from "./gis_map";
import { useState } from "react";
import { flutter } from "../types";



export default function Places(props: flutter) {
    const [token] = useState(props.uid);
    const [polygonCoords] = useState(props.polygonCoords);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBexSBXV5pGF0HjhR9oLLZvw5FjgXycCow",
        libraries: ["places"],
    });

    if (!isLoaded) return <div>Loading...</div>;

    var coords: any[] = []
    if (polygonCoords !== undefined) {
        for (var i of polygonCoords) {
            coords.push({
                "lng": parseFloat(i.lat),
                "lat": parseFloat(i.lng)
            })
        }
    }

    const startingPoint = (coords.length > 0) ? coords[0] : {
        "lng": parseFloat(props.startingPoint.lng),
        "lat": parseFloat(props.startingPoint.lat)
    };

    console.log(startingPoint);
    return <Map
        startPoint={startingPoint}
        polygonCoords={coords}
        token={token}
    />;
}

