export const fetchData = (setJsonCircles: any, setJsonLines: any, token: any) => {
    const myHeaders = new Headers();
    myHeaders.append("uid-token", token);

    const requestOptions: any = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch("https://api.1mark.work/admin/geo?geo_type=point", requestOptions)
        .then(response => response.json())
        .then(result => setJsonCircles(result))
        .catch(error => console.log('error', error));

    fetch("https://api.1mark.work/admin/geo?geo_type=line", requestOptions)
        .then(response => response.json())
        .then(result => setJsonLines(result))
        .catch(error => console.log('error', error));
}
