import {InfoWindow, Marker} from "@react-google-maps/api";
import { GeoJson, Feature } from "../models/geojson";

const setIcons = (data: Feature) => {
    if(data == null) {}
    else {
        switch (data['properties']['ft_PointIcon']) {
            case "small_red":
                return "https://maps.google.com/mapfiles/ms/icons/red-dot.png";
            case "large_yellow":
                return "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
            default:
                return "https://maps.google.com/mapfiles/ms/icons/orange-dot.png";
        }
    }
}

export const circles = (
    jsonCircles: GeoJson,
    handleActiveMarker: any,
    activeMarker: any,
    setActiveMarker: any
    ) => {
    if (jsonCircles != null) {
        return jsonCircles['features'].map((data: any) => (
            <Marker
                position={{
                    lat: data["geometry"]["coordinates"][1],
                    lng: data["geometry"]["coordinates"][0]
                }}
                icon={setIcons(data)}
                onClick={() => handleActiveMarker(data["properties"]["id"])}
            >
                {activeMarker === data["properties"]["id"] ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>
                            <h3>Details</h3>
                            <div><strong>Address:</strong> {data["properties"]["RoadName"]}</div>
                            <div><strong>ID:</strong> {data["properties"]["id"]}</div>
                            <div><strong>LayerHexColor:</strong> {data["properties"]["ft_LayerHexColor"]}</div>
                            <div><strong>CDCShapeFile:</strong> {data["properties"]["ft_CdcShapeFile"]}</div>
                            {data['properties']["link1"] ?
                                <div>
                                    <strong>Link1:</strong>
                                    <a href={data["properties"]["link1"]}> Click Me </a>
                                </div>
                                : null }
                            {data['properties']["link2"] ?
                                <div>
                                    <strong>Link2:</strong>
                                    <a href={data["properties"]["link2"]} > Click Me </a>
                                </div>
                                : null }
                            {data['properties']["link3"] ?
                                <div>
                                    <strong>Link3:</strong>
                                    <a href={data["properties"]["link3"]} > Click Me </a>
                                </div>
                                : null }
                            {data['properties']["link4"] ?
                                <div>
                                    <strong>Link4:</strong>
                                    <a href={data["properties"]["link4"]}> Click Me </a>
                                </div>
                                : null }
                            {data['properties']["link5"] ?
                                <div>
                                    <strong>Link5:</strong>
                                    <a href={data["properties"]["link5"]}> Click Me </a>
                                </div>
                                : null }
                            {data['properties']["link6"] ?
                                <div>
                                    <strong>Link6:</strong>
                                    <a href={data["properties"]["link6"]}> Click Me </a>
                                </div>
                                : null }
                        </div>
                    </InfoWindow>
                ) : null}
            </Marker>
        ));
    }

}
