import './App.css';
import CheckUid from "./pages/checkuid"
import utf8 from "utf8";

import { useSearchParams } from "react-router-dom"
import { flutter as Flutter } from './types';
import { Base64 } from 'js-base64';


function App() {

  const [searchParams] = useSearchParams();
  const mapdata = searchParams.get("mapdata") as string;
  console.log("mapdata param", mapdata)


  var jsonData = JSON.parse(utf8.decode(Base64.decode(decodeURIComponent(mapdata)))) as Flutter;
  console.log("json data", jsonData);


  //testing only
  // var jsonData = { "uid": "xXEx6nyT63TpadZbf6Sj71AqhxC3", "startingPoint": { "lng": "-78.55487610969679", "lat": "41.43259577267027" }, "polygonCoords": [{ "lng": "41.421149", "lat": "-78.551216" }, { "lng": "41.420872", "lat": "-78.550738" }, { "lng": "41.421656", "lat": "-78.549960" }, { "lng": "41.422203", "lat": "-78.550921" }] }

  return <CheckUid uid={jsonData.uid}
    polygonCoords={jsonData.polygonCoords} startingPoint={jsonData.startingPoint} ></CheckUid >

}

export default App;
