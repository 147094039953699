import { SetStateAction, useEffect, useState, useMemo } from "react";
import { fetchData } from "./service/fetch_data";
import { Sidebar } from "./features/sidebar";
import { Search } from "./features/search";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { polygons } from "./features/polygons";
import { circles } from "./features/circles";
import { lines } from "./features/lines";
import { currentLocation } from "./features/current_location";


interface Cords { startPoint: any, polygonCoords: any, token: string }

export function Map(props: Cords) {

    const [selected, setSelected] = useState(null);
    const [center, setCentered] = useState(props.startPoint);
    const [jsonCircles, setJsonCircles] = useState(null);
    const [jsonLines, setJsonLines] = useState(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const [activeLoc, setActiveLoc] = useState(false);

    useEffect(() => {
        fetchData(setJsonCircles, setJsonLines, props.token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleActiveMarker = (marker: SetStateAction<null>) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };


    const style = useMemo(() => ({

        color: 'red',
        marginTop: 20,
        padding: 10,
        backgroundColor: 'green',
        borderRadius: 10,
        borderWidth: 1,


    }), []);

    const options = useMemo(() => ({
        disableDefaultUI: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.BOTTOM_CENTER,
        },
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP,
        },

    }), []);


    return (
        <>
            <Sidebar />
            <div className="places-container" id="search-bar">
                <Search setSelected={setSelected} setCentered={setCentered} />
            </div>
            <GoogleMap
                zoom={16}
                center={center}
                mapContainerClassName="map-container"
                options={options}

            >

                {polygons(props.polygonCoords)}
                {circles(jsonCircles!, handleActiveMarker, activeMarker, setActiveMarker)}
                {lines(jsonLines!)}
                selected && <Marker position={selected!}
                    icon={"https://maps.google.com/mapfiles/ms/icons/grn-pushpin.png"}
                />
                {activeLoc && <Marker
                    position={center}
                    icon={"https://maps.google.com/mapfiles/ms/icons/purple-pushpin.png"}
                />}
            </GoogleMap>

            <button style={style}
                className="places-getLocation"
                onClick={() => currentLocation(setCentered, setActiveLoc)}>

            </button>
        </>
    );
}
