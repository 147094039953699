import { flutter } from "../types";
import Places from "./places";


export default function CheckUid(props: flutter) {
	if (props.uid == null) {
		return <div>no uid don't render since user is not auth</div>
	}
	else {
		return <Places polygonCoords={props.polygonCoords} uid={props.uid} startingPoint={props.startingPoint}></Places>
	}
}