import {
    AiFillCloseSquare,
    AiFillFileImage,
    AiOutlineComment, AiOutlineDelete,
    AiOutlineInfoCircle,
    AiOutlinePaperClip
} from "react-icons/ai";

export function Sidebar(openMenu: any) {
    return (
        <div id="sidebar" className="places-sidebar slide-bar" style={{overflowY: "auto", flex: 1}}>
            <div className="menu-close">
                <AiFillCloseSquare onClick={openMenu} style={{width: "25px", height: "22px", color: "blueviolet"}}/>
            </div>
            <img
                alt="fake img"
                className="default-image"
                src="https://cdn.houseplansservices.com/product/du39te6oqh62mvb1bhgm1cs91h/w1024.jpg?v=23"
                style={{width: "100%"}}
            />
            <div className="image-number">
                <AiFillFileImage />
                <div><span>#</span>  Attached</div>
            </div>
            <div className="data-details">
                <h3>Address</h3>
                <div>Meta data</div>
                <div>Meta data</div>
                <div>Meta data</div>
                <br />
                <hr />
                <br />
                <div className="data-options">
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{
                            backgroundColor: "lightpink",
                            borderRadius: "50px",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center"
                        }}>
                            <AiOutlineInfoCircle style={{width: "60%", height: "60%"}}/>
                        </div>
                        <div style={{fontSize: "12px", fontWeight: 600}}>Ticket</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{
                            backgroundColor: "lightgreen",
                            borderRadius: "50px",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center"
                        }}>
                            <AiOutlineComment style={{width: "60%", height: "60%"}}/>
                        </div>
                        <div style={{fontSize: "12px", fontWeight: 600}}>Comment</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{
                            backgroundColor: "lightblue",
                            borderRadius: "50px",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center"
                        }}>
                            <AiOutlinePaperClip
                                style={{width: "60%", height: "60%"}}
                            />
                        </div>
                        <div style={{fontSize: "12px", fontWeight: 600}}>Attach</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <div style={{
                            backgroundColor: "orangered",
                            borderRadius: "50px",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center"
                        }}>
                            <AiOutlineDelete style={{width: "60%", height: "60%"}}/>
                        </div>
                        <div style={{fontSize: "12px", fontWeight: 600}}>Delete</div>
                    </div>
                </div>
                <br />
                <hr />
                <h4>Comments</h4>
                <hr style={{opacity: .2}}/>
                <div>
                    <p style={{fontSize: "12px"}}>User1 - <span>Date:</span></p>
                    <p style={{fontSize: "12px"}}>Blah blah</p>
                </div>
                <hr style={{opacity: .2}}/>
                <div>
                    <p style={{fontSize: "12px"}}>User2 - <span>Date:</span></p>
                    <p style={{fontSize: "12px"}}>Blah blah - blah</p>
                </div>
                <hr style={{opacity: .2}}/>
                <div>
                    <p style={{fontSize: "12px"}}>User2 - <span>Date:</span></p>
                    <p style={{fontSize: "12px"}}>Blah blah - blah</p>
                </div>
                <hr style={{opacity: .2}}/>
                <div>
                    <p style={{fontSize: "12px"}}>User2 - <span>Date:</span></p>
                    <p style={{fontSize: "12px"}}>Blah blah - blah</p>
                </div>
                <hr style={{opacity: .2}}/>
                <div>
                    <p style={{fontSize: "12px"}}>User2 - <span>Date:</span></p>
                    <p style={{fontSize: "12px"}}>Blah blah - blah</p>
                </div>
            </div>
        </div>
    )
}
